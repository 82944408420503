import React from "react";
import clsx from "clsx";

import { Carousel, Logo, Heading } from "@molecules";
import { Container } from "@atoms";

const Supporters = ({
  heading,
  description,
  slides,
  className: _className,
}) => {
  return (
    <section
      className={clsx(
        "relative overflow-hidden",
        _className,
        "my-10 sm:my-20 lg:my-32"
      )}
    >
      <Container>
        <div className="flex flex-col space-y-8">
          <Heading heading={heading} description={description} />
          <div className="relative hidden md:block">
            <Carousel maxVisible={4} centered>
              {slides?.map(slide => {
                return <Logo key={slide.uid} {...slide} />;
              })}
            </Carousel>
          </div>
          <div className="grid grid-cols-2 sm:gap-y-4 md:hidden">
            {slides?.map(slide => {
              return <Logo key={slide.uid} {...slide} />;
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

Supporters.defaultProps = {};

export default Supporters;
